import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate,useLocation } from "react-router-dom";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { GoogleLogin } from "react-google-login";
import { signInWithPopup } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "",
//   authDomain: "",
//   projectId: "",
//   storageBucket: "",
//   messagingSenderId: "",
//   appId: ""
// };

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const google = new GoogleAuthProvider();
// const facebook = new FacebookAuthProvider();

const Login = () => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
const location=useLocation()
  const [password, setPassword] = useState("");
  console.log("email and password", location.state);

  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      console.log();
      if (password === "pro" || password === "health") {
        localStorage.setItem("kb", location.state);
        localStorage.setItem("isLoggedIn", true);
        navigate("/");
      } else {
        alert("Invalid credentials");
      }
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  // const socialSignin = async (provider) => {
  //   try {
  //     const result = await signInWithPopup(auth, provider);  
  //     const user = result.user;
  //     console.log('Social Signin:', user);

  //     // Save user data to local storage
  //     localStorage.setItem('user', JSON.stringify(user));

  //     // Navigate to "/womenshealth" after a successful social login
  //     localStorage.setItem('kb', 'womenshealth');
  //     navigate('/womenshealth');
  //   } catch (error) {
  //     console.error('Social Signin Error:', error);
  //     alert('Social login failed. Please try again.');
  //   }
  // };

  // const handleGoogleSignIn=()=>{
  //   socialSignin(new GoogleAuthProvider())
  // }
  // const handleFacebookSignIn=()=>{
  //   socialSignin(new FacebookAuthProvider())
  // }

  return (
    <>
      <section class="vh-100 wh-authentication">
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div class="card shadow-sm wh-radius border-0">
                <div class="card-body p-5 text-center">
                  <img onClick={() => navigate("/")} className="mx-auto d-block auth-logo mb-5 cursor-pointer w-auto" width="320" src={"../assets/images/logo-1.png"} />
                  <form>
                    <div className="d-flex gap-2 wh-white-bg wh-radius px-3 py-3 wh-search wh-border mb-5">
                      <input style={{ boxShadow: "none" }} class="form-control" id="floatingPassword" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" autocomplete="password" required />
                      <button className="" type="submit" onClick={() => handleSubmit()} ><i className={`bi bi-arrow-right-short`}></i></button>
                    </div>
                    <p className="fw-semibold text-center mb-2">Social Login</p>
                    <div className="d-flex align-items-center justify-content-center gap-2">
                      {/* <div className="wh-social-login" onClick={(e) => handleFacebookSignIn()}><i class="bi bi-facebook d-flex"></i></div> */}
                      <div className="wh-social-login"
                        // onClick={(e) => handleGoogleSignIn()}
                      ><i class="bi bi-google d-flex"></i></div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
