import React, { useState, useEffect, useRef } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Result = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query =
    window.location?.pathname[window.location?.pathname?.length - 1];
  const [currentKb, setCurrentKb] = useState(localStorage.getItem("kb"));
  const queryParams = new URLSearchParams(search);
  const [chat, setChat] = useState([]);
  const [chatid, setChatId] = useState([]);
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [load, setload] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [lastMesssage, setlastMesssage] = useState(null);
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_PASSWORD;
  const [Added, setAdded] = useState(0);
  const [removeMessage, setRemoveMessage] = useState(false);
  const basicAuth = "Basic " + btoa(username + ":" + password);
  const chatContainerRef = useRef();
  const [displayedMessage, setDisplayedMessage] = useState(""); // State to display characters one by one
  const [completeTyping, setCompletedTyping] = useState(true);
  const [references, setReferences] = useState([]);
  const [allMessages, setAllMessages] = useState([]);
  const [rating, setRating] = useState();
  const [messageIndex, setMessageIndex] = useState();
  const [toggle, settoggle] = useState(false);
  const [method, setMehod] = useState(4);
  const [isLoggedIn,setIsLoggedIn]=useState(localStorage.getItem("isLoggedIn"))
  console.log("is logged ib user",isLoggedIn);
  const rootUrl = process.env.NODE_ENV === "production"
      ? "https://wellspring-backend-server.vercel.app"
      : "https://wellspring-backend-server.vercel.app";

  const scrollPageToBottom = () => {
    // window.scrollTo({
    //   top: document.documentElement.scrollHeight,
    //   behavior: "smooth",
    // });

    const scrollPosition = document.documentElement.scrollHeight * 0.7;

    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  };



  const sendMessage = async (type) => {
    try {
      console.log("=====>123", `${rootUrl}/api/v1/chats`);
      setload(!load);

      if (query == "1") {
        setRemoveMessage(true);
        setMessages((prev) => [
          ...prev,
          {
            user: {
              role: "user",
              message:
                type == "rewrite"
                  ? messages[messages?.length - 1].user?.message
                  : userInput,
            },
            assistant: { role: "assistant", message: "", complete: false },
          },

          // { role: "assistant", message: "" },
        ]);
        await createNewChat();
        setUserInput("");
      } else {
        setRemoveMessage(true);

        setMessages((prev) => [
          ...prev,
          {
            user: {
              role: "user",
              message:
                type == "rewrite"
                  ? messages[messages?.length - 1].user?.message
                  : userInput,
            },
            assistant: { role: "assistant", message: "", complete: false },
          },

          // { role: "assistant", message: "" },
        ]);

        console.log("sending message to chat bot", chatId, userInput);
        // Post a new message to the chat
        const response = await axios.post(
          `${rootUrl}/api/v1/send-messages`,
          {
            chatId: chatId,
            message:
              type == "rewrite"
                ? `please answer it in different words \"${
                    messages[messages?.length - 1].user?.message
                  }\  "`
                : userInput,
          },
          {
            headers: {
              Authorization: basicAuth,
              "Content-Type": "application/json",
            },
          }
        );
        setUserInput("");
        console.log("send", response.data);
        if (response.data) {
          console.log("response data of messge", Added, response.data);
          // Update the messages with the new message
          setAdded((prev) => prev + 1);
          // setMessages((prev) => [...prev, response.data]);
          // setTimeout(() => fetchMessages("recall"), 3000);
          fetchMessages();
        } else {
          console.error("Error sending message:", response.statusText);
        }
      }
      setload(false);
    } catch (error) {
      console.error("An error occurred while sending the message:", error);
      setload(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      scrollPageToBottom();
      sendMessage();
    }
  };
  const fetchMessages = async (type) => {
    try {
      // setload(true);
      // Fetch messages for the specified chatId
      const response = await axios.post(
        `${rootUrl}/api/v1/messages`,
        { chatId: chatId },
        {
          headers: {
            Authorization: basicAuth,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("fetch", response?.data);

      if (response?.data) {
        setMessages(response?.data?.messages);
        setAllMessages(response?.data?.messages);
        const last =
          response?.data?.messages[response?.data?.messages?.length - 1];
        setlastMesssage(
          response?.data?.messages[response?.data?.messages?.length - 1]
        );
      }
    } catch (e) {
      console.log("error getting messages =>", e);
    }
  };
  useEffect(() => {
    console.log("running...");

    // scrollPageToBottom();

    if (!lastMesssage?.assistant?.complete || query == "1") {
      console.log("====================================");
      console.log("our useefect working");
      console.log("====================================");
      fetchMessages();
    } else {
      setload(false);
    }
  }, [chatId, lastMesssage, chat]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("=====>",`Bearer ${username}:${password}`);

  //     fetchMessages();
  //   }, 2000)

  // }, [Added])

  const createNewChat = async () => {
    console.log("=====>", `Bearer ${username}:${password}`);
    try {
      // setload(true);
      const kb = localStorage.getItem("kb");
      const type = localStorage.getItem("type");
      // console.log("====================================",kb);
      // console.log( method);
      // console.log("====================================", kb == "womenshealth"
      // ? method == 4
      //   ? "gpt4-chat-10-refs"
      //   : "gpt35-chat-10-refs"
      // : kb == "menopause"
      // ? "gpt35-chat-5-refs-no"
      // : method == 4
      // ? "gpt4-chat-10-refs"
      // : "gpt35-chat-5-refs",);
      const response = await axios.post(
        `${rootUrl}/api/v1/chats`,
        {
          kb: kb ? kb : "menopause",
          method:
            kb == "womenshealth"
              ? method == 4
                ? "gpt4-chat-10-refs"
                : "gpt35-chat-10-refs"
              : kb == "menopause"
              ? type == "nordic"
                ? "gpt35-chat-5-refs-no"
                : "gpt35-chat-10-refs"
              : method == 4
              ? "gpt4-chat-10-refs"
              : "gpt35-chat-10-refs",

          title:
            kb == "womenshealth"
              ? method == 3.5
                ? "Academic GPT 3.5"
                : "Academic GPT 4"
              : kb == "links"
              ? "Norwegian GPT 3.5"
              : "Chat 1",
          message: userInput,
        },
        {
          headers: {
            Authorization: `Bearer ${username}:${password}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data) {
        // Save the chat data to local storage
        // const savedChats = JSON.parse(localStorage.getItem("chats")) || [];
        // const updatedChats = [response.data, ...savedChats];
        // localStorage.setItem("chats", JSON.stringify(updatedChats));
        // setChat(updatedChats);
        // console.log("Chats updated =>", updatedChats);

        const savedChats = JSON.parse(localStorage.getItem("chats")) || [];
        const newChat = { ...response.data, kb: currentKb };
  const updatedChats = [newChat, ...savedChats];
  localStorage.setItem("chats", JSON.stringify(updatedChats));
  setChat(updatedChats);
  console.log("Chats updated =>", updatedChats);

  
        navigate(`/chat/${response.data.chatid}`);
      } else {
        console.error("Error creating new chat:", response.statusText);
      }
      // setload(false);
    } catch (error) {
      console.error(
        "An error occurred while creating a new chat:",
        error.response
      );
      setload(false);
    }
  };
  const addNewChat = async () => {
    try {
      // const updatedChats = [...chat,{chatid:1,lastMesssage:"New Chat"}];
      // localStorage.setItem("chats", JSON.stringify(updatedChats));
      navigate(`/chat/1`);

      setChat((prev) => [{ chatid: "1", firstMessage: "New Chat", kb: currentKb }, ...prev]);
    } catch (error) {
      console.log("error adding new Chat", error);
    }
  };
  useEffect(() => {
    console.log("running...");
    if (query != "1") {
      const savedChats = JSON.parse(localStorage.getItem("chats")) || [];
      setChat(savedChats);
    } else if (query == "1") {
      const savedChats = [
        { chatid: 1, firstMessage: "New Chat", kb: currentKb },
        ...JSON.parse(localStorage.getItem("chats")),
      ] || [{ chatid: 1, firstMessage: "New Chat", kb: currentKb }];
      setChat(savedChats);
    }
  }, []);

  const [copied, setCopied] = useState(false);

  const handleCopyToClipboard = async (text) => {
    try {
      console.log("copting test", text);
      await navigator.clipboard.writeText(text);
      setCopied(true);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  const handleRatingMessage = async (star, index) => {
    const totalValue = rating * 20;
    try {
      console.log("payload of rating", {chatId: chatId, rating: parseInt(star), index: parseInt(index),});
      if (typeof parseInt(rating) == "number") {
        const response = await axios.post(`${rootUrl}/api/v1/rate-message`,
          {chatId: chatId, rating: parseInt(star), index: index,},
          {headers: {"Content-Type": "application/json",},}
        );
        if (response.status) {
          // toast.success("Rating Submitted");
          fetchMessages();
        }
      } else {
        toast.error("Invalid rating value");
      }
    } catch (error) {
      console.log("error adding rating to message", error);
    }

    console.log("Selected Rating:", star);
    console.log("Total Value:", index);
  };

  const handleShareText = (text) => {
    if (navigator.share) {
      navigator.share({title: "Chat Message", text: text,}).then(() => console.log("Shared successfully")).catch((error) => console.error("Error sharing:", error));
    } else {
      console.warn("Web Share API not supported on this browser");
    }
  };

  const Links = [
    // {
    //   id: 1,
    //   name: "Home",
    // },
    {
      id: 2,
      name: "Back",
    },
  ];


  const [typingIndex, setTypingIndex] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const truncateText = (message) => {
    const parts = message.split('"');
    return parts[1];
  };


  const [hoveredStar, setHoveredStar] = useState(0);

  const handleHover = (star) => {
    setHoveredStar(star);
  };

  const handleClick = (star) => {
    setRating(star);
  };

  const handleLeave = () => {
    setHoveredStar(0);
  };

  const [showAll, setShowAll] = useState(false);

  const handleShowAll = (i) => {
    showAll == i ? setShowAll(null) : setShowAll(i);
  };

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  const userData = JSON.parse(localStorage.getItem("user"));

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("kb");
    localStorage.removeItem("chats");
    localStorage.removeItem("isLoggedIn")
    localStorage.removeItem("type")
    navigate("/");
  };

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <>
      <section className="wh-chat px-xl-5 px-md-2 px-2 py-5">
        <div className="container-fluid">
          <div className={toggle == true ? "whc-sidebar open wh-white-bg wh-radius p-3": "whc-sidebar wh-white-bg wh-radius p-3"}>
            <div onClick={() => settoggle(false)} className="sm-sidebar-close-btn"><i class="bi bi-x-lg"></i></div>
            <img className="mx-auto d-block mb-5 cursor-pointer" src="../assets/images/logo-1.png" alt="" width="130" onClick={() => navigate("/")} />
            <div style={{backgroundColor:'var(--color3)'}} className="wh-radius p-3 mb-3">
            <h3 className="fw-semibold mb-0">Disclaimer:</h3>
            <p className="font-size-12 mb-0">Wellspring organises your information and makes it accessible and useful for you. Wellspring provides customised access to clinical guidelines and scientific literature. Wellspring does not provide medical advice, and no warrant is given that answers are free of errors, complete or up-to-date. Always consult a healthcare professional for any medical decisions.</p>
            </div>
            <ul className="list-unstyled wh-lisks-list mb-4">
              {/* {Links.map((l, i) => ( */}
                <li>
                  <a className="cursor-pointer" onClick={handleBackClick}>Back <i class="bi bi-arrow-right"></i></a>
                </li>
              {/* ))} */}
            {/* {isLoggedIn&&  <div className="sp-switcher">
                <label class="form-check-label" for="flexSwitchCheckDefault">GPT 3.5</label>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" onChange={() => setMehod(method == 3.5 ? 4 : 3.5)} role="switch" id="flexSwitchCheckDefault" checked={method == 4 ? true : false} />
                  <label class="form-check-label" htmlFor="flexSwitchCheckDefault">GPT 4</label>
                </div>
              </div>} */}
            </ul>
            <ul className="list-unstyled wh-lisks-list mb-0">
              <li className="active"><a className="cursor-pointer" onClick={query != "1" ? addNewChat : null}>New Chat <i class="bi bi-plus-lg"></i></a></li>
              <ul className="list-unstyled whc-chat-list">
                {chat.filter(chat => chat.kb === currentKb)?.map((chat, i) => (
                  <li key={i}><a href={`/chat/${chat.chatid}`}>{chat?.firstMessage}{" "}<i className="bi bi-pencil-square"></i></a></li>
                ))}
              </ul>
            </ul>
            <div className="whc-sidebar-footer">
              {userData ? (
                <div className="d-flex justify-content-between align-items-center wh-hover-icon px-2">
                  <div className="d-flex gap-2 align-items-center">
                    <img className="wh-userimage" src={userData.photoURL} alt="" />
                    <div className="mx-1">
                      <p className="font-size-16 mb-0">{userData.displayName}</p>
                      <p className="font-size-10 mb-0" style={{ color: "#757575" }}>User</p>
                    </div>
                  </div>
                  <div class="dropdown">
                    <a style={{textDecoration: "none"}} class="" role="button" data-bs-toggle="dropdown" aria-expanded="false" ><i className="bi bi-gear"></i></a>
                    <ul class="dropdown-menu">
                      <li>
                        <a class="dropdown-item" onClick={handleLogout}>Logout</a>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
              <div className="sidebar-footer">
                <div className="w-75 border-top mx-auto d-block wh-border-color my-4"></div>
                <p className="text-center font-size-10">© Copyright {new Date().getFullYear()}, All Rights Reserved.</p>
              </div>
            </div>
          </div>
          <div className="whc-chat">
            <div onClick={() => settoggle(!toggle)} className="sm-sidebar-btn">
              <i class="bi bi-filter-left"></i>
            </div>
            <div ref={chatContainerRef} className={`chat-scrolling ${messages?.length > 0 ? "more-scrolling" : ""}`} id="chatContainer">
              {messages?.length > 0 && messages?.map((message, index) => (
                  <div className="whc-chat-row border-bottom wh-border-color py-4">
                    <div className="whc-chat-message">
                      <h2 className="fw-semibold mb-4">{message?.user?.message?.includes("please answer it")? truncateText(message?.user?.message): message?.user?.message}</h2>
                      {!message?.assistant?.message?.length ? (
                        <p className="font-size-16 mb-4 answer-text">AI typing...</p>
                      ) : (
                        <p className={`font-size-16 mb-4 ${message?.assistant?.index == messages?.length * 2 && !message?.assistant?.complete && "answer-text"}`}>
                      {message?.assistant?.message?.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                            {line}
                            <br />
                            </React.Fragment>
                        ))}
                        </p>
                      )}
                      {message?.assistant?.complete && (
                        <div className="row mb-4 gy-2">
                          <div className="col-md-6 d-flex gap-2">
                            <a className="btn wh-button" onClick={() => handleCopyToClipboard(window.location.href)} data-bs-toggle="tooltip" data-bs-title="Copy Link">
                              <i className="bi bi-share"></i> Share
                            </a>
                            {index == allMessages?.length - 1 && !message?.user?.message.includes("please answer it") && (
                                <a data-bs-toggle="tooltip" data-bs-title="Rewrite this answer" className="btn wh-button" onClick={() => sendMessage("rewrite")} >
                                  <i className="bi bi-repeat"></i> Rewrite
                                </a>
                              )}
                            {index == allMessages?.length - 1 && message?.user?.message.includes("please answer it") && (
                                <a data-bs-toggle="tooltip" data-bs-title="Already Rewritten" className="btn grey-button" >
                                  <i className="bi bi-repeat"></i> Rewritten
                                </a>
                              )}
                          </div>
                          <div className="col-md-6 d-flex gap-2 justify-content-md-end" onClick={() => {setMessageIndex(message?.assistant?.index);}}>
                            <div className="star-rating" onMouseLeave={handleLeave} data-bs-toggle="tooltip" data-bs-title="Rating">
                              {[20, 40, 60, 80, 100].map((star) => (
                                <i key={star} value={message?.assistant?.rating ? message?.assistant?.rating : 0} onMouseEnter={() => handleHover(star)}
                                className={`bi bi-star-fill ${star <= (hoveredStar || message?.assistant?.rating) ? "active" : ""}`}
                                onClick={() => handleRatingMessage(star, message?.assistant?.index)}></i>
                              ))}
                            </div>
                            <a data-bs-toggle="tooltip" data-bs-title="Copy" className="btn wh-button wh-button-icon" onClick={() => handleCopyToClipboard(message?.assistant?.message)} role="button" >
                              <i className="bi bi-clipboard"></i> Copy
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    {message?.assistant?.complete && (
                      <div className="whc-chat-source">
                        <h3 className="fw-semibold mb-4">Sources</h3>
                        <div className="row gy-3 gx-3 mb-5">
                          {message?.assistant?.references?.map((sl, i) => (
                            <div key={i} className="col-md-12 overflow-hidden">
                              <p>
                                {showAll == i ? sl?.refs[0]?.content : sl?.refs[0]?.content.split(" ").slice(0, 25).join(" ")}{" "}{`\n`}
                                {sl?.refs[0]?.content.split(" ").length > 25 && (
                                  <a style={{ color: "var(--color4)" }} className="cursor-pointer" onClick={() => handleShowAll(i)}>
                                    <u>{showAll == i ? "Show Less" : "Show More"}</u>
                                  </a>
                                )}
                              </p>
                              {/* <p>{sl?.refs[0]?.page && <>Page No: {sl?.refs[0]?.page}</>} Paragraph: {sl?.refs[0]?.paragraph}</p> */}
                              <a className="wh-pq" href={sl?.type === 'pdf' && sl?.refs[0]?.page ? `${sl?.url}#page=${sl?.refs[0]?.page}` : sl?.url} target="blank">
                                {sl?.title.substring(0, 100)}...{" "}<i class="bi bi-arrow-right"></i>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>

          {!load && 
          <div className="d-flex gap-2 wh-white-bg wh-radius px-3 py-3 wh-search wh-search-result-page">
            <input onChange={(e) => setUserInput(e.target.value)} type="text" name="search" placeholder={"Ask anything…"} value={userInput} onKeyDown={handleKeyDown} />
            <button className={`${load ? "active" : ""}`} type="submit" onClick={userInput ? sendMessage : null} >
              {load ? (<span class="spinner-border spinner-border-sm" aria-hidden="true"></span>) : (<i className={`bi bi-arrow-right-short`}></i>)}
            </button>
          </div>}
        </div>
      </section>

      {/* <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{ "--bs-modal-width": "30%" }}
        >
          <div class="modal-content">
            <div class="modal-header py-2">
              <div>
                <h5 class="modal-title">Help us improve</h5>
                <p className="mb-0">
                  Provide additional feedback on this answer. Select all that
                  apply.
                </p>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="d-flex flex-wrap gap-2 mb-3">
                {helpUsImprove?.map((hp, i) => (
                  <>
                    <input
                      type="checkbox"
                      class="btn-check"
                      id={`help-improve-${hp.id}`}
                      autocomplete="off"
                    />
                    <label
                      class="btn whc-select-btn"
                      for={`help-improve-${hp.id}`}
                    >
                      {hp.name}
                    </label>
                  </>
                ))}
              </div>
              <div class="">
                <label for="exampleFormControlInput1" class="form-label">
                  How can the response be improved? (optional)
                </label>
                <input
                  style={{ boxShadow: "none", border: "var(--border)" }}
                  type="text"
                  class="form-control rounded-4"
                  id="exampleFormControlInput1"
                  placeholder="Your feedback..."
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button type="button" class="btn green-btn">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        class="modal fade"
        id="add-technical-category"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          style={{ "--bs-modal-width": "25%" }}
        >
          <div class="modal-content">
            <div class="modal-header py-2">
              <h5 class="modal-title">Add Rating</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="">
                <input
                  style={{ boxShadow: "none", border: "var(--border)" }}
                  type="number"
                  value={rating}
                  onChange={(e) => {
                    setRating(e.target.value);
                  }}
                  class="form-control rounded-4"
                  id="exampleFormControlInput1"
                  placeholder="Rate this out of 100"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn green-btn"
                onClick={() => handleRatingMessage()}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Result;
