import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { batch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Home = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [initialQuestion, setinitialQuestion] = useState(
    queryParams.get("question")
  );
  const [currentKb, setCurrentKb] = useState(localStorage.getItem("kb"));
  const [type, setType] = useState(localStorage.getItem("type"));
  const [isLoggedIn,setIsLoggedIn]=useState(false)
  const [chatsList, setChatsList] = useState([]);
  const [chat, setChat] = useState([]);
  const [chatid, setChatId] = useState([]);
  const [load, setload] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [userInput, setUserInput] = useState("");
  const username = process.env.REACT_APP_USERNAME;
  const password = process.env.REACT_APP_PASSWORD;
  const rootUrl =
    process.env.NODE_ENV === "production"
      ? "https://wellspring-backend-server.vercel.app"
      : "https://wellspring-backend-server.vercel.app";
  // const rootUrl =  "https://wellspring-backend-server.vercel.app"

  const basicAuth = "Basic " + btoa(username + ":" + password);
  console.log("=====>2", type, currentKb);
  const [method, setMehod] = useState(4);

  // const createNewChat = async () => {
  //   try {
  //     setload(true);
  //     const response = await axios.post(
  //       `${rootUrl}/api/v1/chats`,
  //       {
  //         kb: 'wellspring-live',
  //         method: 'gpt35-chat-5-refs',
  //         title: 'Chat 1',
  //         message: "Hi",
  //       },
  //       {
  //         headers: {
  //           Authorization: basicAuth,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );

  //     if (response.data) {
  //       // Save the chat data to local storage
  //       const savedChats = JSON.parse(localStorage.getItem('chats')) || [];
  //       const updatedChats = [...savedChats, response.data];
  //       localStorage.setItem('chats', JSON.stringify(updatedChats));
  //       setChat(updatedChats);
  //       // navigate(`/result/${response.data.chatid}`);
  //     } else {
  //       console.error('Error creating new chat:', response.statusText);
  //     }
  //     setload(false);
  //   } catch (error) {
  //     console.error('An error occurred while creating a new chat:', error.response);
  //     setload(false);
  //   }
  // };

  useEffect(() => {
    // Retrieve chats from local storage on component mount
    setIsLoggedIn(localStorage.getItem("isLoggedIn"))
    const savedChats = JSON.parse(localStorage.getItem("chats")) || [];
    setChat(savedChats);
  }, []);

  const OpenChat = async (userMessage) => {
    try {
      console.log("11=====>", `Bearer ${username}:${password}`, rootUrl);
      const kb = localStorage.getItem("kb");
      console.log("====================================");
      console.log("KB IS  s=>", kb, "=>", method);
      console.log("====================================");
      setload(true);
      const response = await axios.post(
        `${rootUrl}/api/v1/chats`,
        {
          kb: kb ? kb : "menopause",
          method:
            kb == "womenshealth"
              ? method == 4
                ? "gpt35-chat-10-refs"
                : "gpt35-chat-10-refs"
              : kb == "menopause"
              ? "gpt35-chat-10-refs"
              : method == 4
              ? "gpt4-chat-10-refs"
              : "gpt35-chat-10-refs",
          title:
            kb == "womenshealth"
              ? method == 4
                ? "Academic GPT 3.5"
                : "Academic GPT 4"
              : kb == "links"
              ? "Norwegian GPT 3.5"
              : "Chat 1",
          message: userInput,
        },
        {
          headers: {
            Authorization: basicAuth,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data, "response");

      if (response.data) {
        navigate(`/chat/${response.data.chatid}`);
        setChat([...chat, response.data]);
        setChat((prevChat) => [...prevChat, response.data]);
        // setTimeout(() => {

        // }, 3000);
        // const savedChats = JSON.parse(localStorage.getItem("chats")) || [];
        // const updatedChats = [...savedChats, response.data];
        // localStorage.setItem("chats", JSON.stringify(updatedChats));
        // setChat(updatedChats);

        const savedChats = JSON.parse(localStorage.getItem("chats")) || [];
        const newChat = { ...response.data, kb: currentKb };
        const updatedChats = [newChat, ...savedChats];
        localStorage.setItem("chats", JSON.stringify(updatedChats));
        setChat(updatedChats);
        console.log("Chats updated =>", updatedChats);
      } else {
        console.error("Error creating new chat:", response.statusText);
      }
      setload(false);
    } catch (error) {
      console.error(
        "An error occurred while creating a new chat:",
        error.response
      );
      setload(false);
    }
  };

  const handleSearch = () => {
    OpenChat(userInput);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      handleSearch();
    }
  };

  const Links = [
    {
      id: 1,
      name: "Home",
      link: "/home",
    },
    {
      id: 2,
      name: "Wellspring Pro +",
      link: "#",
    },
    {
      id: 3,
      name: "Discover",
      link: "#",
    },
    {
      id: 4,
      name: "Library",
      link: "#",
    },
  ];

  const changeKB = (kb, type) => {
    console.log("KB IS =>", kb, method, type);
    if (!type) {
      localStorage.setItem("kb", kb);
      localStorage.setItem("type", null);
      setType(null);
    } else {
      localStorage.setItem("kb", kb);
      localStorage.setItem("type", type);
      setType(type);
    }
    setCurrentKb(kb);
  };

  const userData = JSON.parse(localStorage.getItem("user"));

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("kb");
    localStorage.removeItem("chats");
    navigate("/");
  };

  useEffect(
    () => {
      const handleStorageChange = () => {
        console.log("Storage changed");
        setCurrentKb(localStorage.getItem("kb"));
      };

      window.addEventListener("storage", handleStorageChange);

      return () => {
        window.removeEventListener("storage", handleStorageChange);
      };
    },
    [
      /* any dependencies if needed */
    ]
  );

  return (
    <>
      {/* {load && <Loader /> } */}
      <section className="wh-home px-xl-5 px-md-2 px-2 py-5">
        <div className="d-flex flex-wrap">
          <div className={toggle == true ? "column-1 open" : "column-1"}>
            <div className="wh-sidebar wh-white-bg wh-radius p-3">
              <div onClick={() => settoggle(false)} className="sm-sidebar-close-btn"><i class="bi bi-x-lg"></i></div>
              <img className="mx-auto d-block cursor-pointer" src="../assets/images/logo-1.png" alt="" width="130" onClick={() => navigate("/")} />
              <div className="wh-light-bg wh-radius p-3">
                <p className="text-center font-size-14 mb-0">Wellspring AI unlocks the power of domain-specific knowledge within Women’s Health</p>
              </div>
              <ul className="list-unstyled wh-lisks-list">
                <li className={currentKb == "menopause" && type !== "nordic" ? "active" : null}>
                  <a onClick={() => changeKB("menopause")}> Wellspring <i class="bi bi-arrow-right"></i></a>
                </li>
                {currentKb == "menopause" && type !== "nordic" ? <p className="font-size-12 mb-2">Information about perimenopause and menopause, including symptoms and treatment options. Information provided by Newson Health and balance, whose mission is to make menopause support inclusive and accessible to all.</p>: null}

                <li className={currentKb == "links" ? "active" : null}>
                  <a onClick={() => isLoggedIn ? changeKB("links"): navigate("/login", { state: "links" })}>
                    Wellspring Pro <i class="bi bi-arrow-right"></i>
                  </a>
                </li>
                {currentKb == "links" ? <p className="font-size-12 mb-2">Selected, peer-reviewed articles about the perimenopause and menopause that you can interact with using AI. The knowledge base includes over 1,000 articles — more are added on a ongoing basis as they are published.</p> : null}
                <li className={currentKb == "womenshealth" ? "active" : null}>
                  {/* <a onClick={() => changeKB("womenshealth")}> */}
                  <a onClick={() => isLoggedIn?changeKB("womenshealth"): navigate("/login", { state: "womenshealth" })}>
                    Wellspring Health <i class="bi bi-arrow-right"></i>
                  </a>
                </li>
                {currentKb == "womenshealth" ? <p className="font-size-12 mb-2">National guidelines for women’s health, including over 200 resources from RCOG, NICE, as well as other selected publications. Interact with the content using natural language, with answers guiding you through the reference material.  </p> : null}
                <li className={currentKb == "menopause" && type == "nordic" ? "active" : null}>
                  <a className="cursor-pointer" onClick={() => changeKB("menopause", "nordic")}>
                    Wellspring Nordic <i class="bi bi-arrow-right"></i>
                  </a>
                </li>
                {currentKb == "menopause" && type == "nordic" ? <p className="font-size-12 mb-2">Information about perimenopause and menopause, with answers provided in Norwegian. Information from Newson Health and balance, the free menopause support tool developed by menopause specialist Dr Louise Newson.</p>: null }
                
                <li className={ currentKb == "wellspring-custom" ? "active" : null}>
                  <a className="cursor-pointer" onClick={() => changeKB("wellspring-custom")}>
                    Wellspring Custom <i class="bi bi-arrow-right"></i>
                  </a>
                </li>
                {currentKb == "wellspring-custom" ? <p className="font-size-12 mb-2">Your opportunity to upload your own content to a custom knowledge base, and interact with it using natural language AI. Make your knowledge come alive.</p> : null}
                <div className="sp-switcher">
                  <label class="form-check-label" for="flexSwitchCheckDefault">
                    GPT 3.5
                  </label>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" onChange={() => setMehod(method == 3.5 ? 4 : 3.5)} role="switch" id="flexSwitchCheckDefault" checked={method == 4 ? true : false} />
                    <label class="form-check-label" for="flexSwitchCheckDefault" >GPT 4</label>
                  </div>
                </div>
              </ul>
              {userData ? (
                <div className="d-flex justify-content-between align-items-center wh-hover-icon px-2">
                  <div className="d-flex gap-2 align-items-center">
                    <img className="wh-userimage" src={userData.photoURL} alt="" />
                    <div className="mx-1">
                      <p className="font-size-16 mb-0">{userData.displayName}</p>
                      <p className="font-size-10 mb-0" style={{ color: "#757575" }}>User</p>
                    </div>
                  </div>
                  <div class="dropdown">
                    <a style={{ textDecoration: "none" }} class="" role="button" data-bs-toggle="dropdown" aria-expanded="false" > <i className="bi bi-gear"></i></a>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" onClick={handleLogout}>Logout</a></li>
                    </ul>
                  </div>
                </div>
              ) : null}
              <div className="w-75 border-top mx-auto d-block wh-border-color"></div>
              <p className="text-center font-size-10">© Copyright {new Date().getFullYear()}, All Rights Reserved.</p>
            </div>
          </div>
          <div className="column-2 align-self-center px-md-5">
            <div onClick={() => settoggle(!toggle)} className="sm-sidebar-btn">
              <i class="bi bi-filter-left"></i>
            </div>
            <img className="mx-auto d-block mobile-logo mb-3 cursor-pointer" src="../assets/images/logo-1.png" alt="" width="130" onClick={() => navigate("/")} />
            
            <h1 className="fw-semibold text-center mb-5 text-lowercase">
              {currentKb === "menopause" ? type == "nordic"   ? "Hvordan kan vi hjelpe deg?" : "How can We assist you today?" : currentKb === "womenshealth" ? "Your learning. Reimagined" : currentKb === "links" ? "Makes your knowledge come alive" : currentKb === "wellspring-custom" ? "Your knowledge. Organised. Searchable." : ""}
            </h1>
            <div className="d-flex gap-2 wh-white-bg wh-radius px-3 py-3 mb-5 wh-search">
              <input type="text" name="search" onChange={(e) => setUserInput(e.target.value)} placeholder={type=="nordic"?"Svar formuleres...":"Ask anything…"} id="search" onKeyDown={handleKeyDown} autoComplete="search" />
              <button className={`${load == true ? "active" : ""}`} type="button" onClick={handleSearch} >
                {load == true ? (<span class="spinner-border spinner-border-sm" aria-hidden="true"></span>) : (<i className={`bi bi-arrow-right-short`}></i>)}
              </button>
            </div>
            <p className="fw-semibold text-center mb-2">Developed in partnership</p>
            <div className="d-flex gap-3 justify-content-center">
              {currentKb === "womenshealth" ? <a target="blank">
                <img width={80} src="../assets/images/222.png" alt=""/>
              </a> : currentKb === "wellspring-custom" ? <img width={80} src="../assets/images/444.png" alt=""/> : <a href="https://www.nhmenopausesociety.org/" target="blank">
                <img style={{ mixBlendMode: "multiply" }} width={150} src="../assets/images/NH_logo.jpg" alt=""/>
              </a>}
              {type == "nordic" ? <a target="blank">
                <img width={80} src="../assets/images/333.png" alt=""/>
              </a> : <a href="https://www.ucl.ac.uk/womens-health/" target="blank">
                <img style={{ mixBlendMode: "multiply" }} width={150} src="https://cdn.wpmeducation.com/53544f/8c75a86022/F363-EC62-4310-832F-6C54.png" alt=""/>
              </a>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
